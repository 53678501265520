import React from 'react';
import { ServiceProvider } from './src/context/LoaderContext';

import 'sal.js/dist/sal.css';
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
    <ServiceProvider>
        {element}
    </ServiceProvider>
);