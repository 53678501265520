import React, { createContext, useState, ReactNode, useContext } from 'react';

// Define the shape of the context
interface LoaderContextType {
  isLoaded: boolean;
  setLoaded: (data: boolean) => void; 
}

// Create a default context value
const defaultContext: LoaderContextType = {
  isLoaded: false,
  setLoaded: () => {}
};

// Create the context
const ServiceContext = createContext<LoaderContextType>(defaultContext);

// Create a provider component
export const ServiceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const onSetData = (data: boolean) => {
      setIsLoaded(data);  // Updates the isLoaded state
    };
  return (
    <ServiceContext.Provider value={{ isLoaded, setLoaded: (_data: boolean) => onSetData(_data) }}>
      {children}
    </ServiceContext.Provider>
  );
};

// Custom hook to use the context
export const useService = () => useContext(ServiceContext);